import { CardShowOnHover, ICard } from "../CardShowOnHover";
const DesktopValues = ({ items }: { items: ICard[] | [] }) => {
  return (
    <ul className="list">
      {items.map((item) => (
        <CardShowOnHover card={item} />
      ))}
    </ul>
  );
};
export default DesktopValues;
