
export const projects = [
  { name: "Raznik", src: "/assets/projects/raznik.jpg", adress: "מגדל הפארק - רזניק 5"  , city:" נתניה"  },
  { name: "Bzalel", src: "assets/projects/bzlal.jpg", adress: "בצלאל 1", city:" תל אביב" },
  { name: "Mayyan", src: "assets/projects/mayaan.jpg", adress: "המעיין 39", city:"גבעתיים"  },
  { name: "Zuk", src: "assets/projects/zuk.jpg", adress: "מגדל הצוק - הידידות", city:" נתניה" },
  { name: "Modliani", src: "assets/projects/modliani.jpg", adress: "מודליאני" ,  city:" תל אביב"},
  { name: "Sutin",  src: "assets/projects/sutin.jpg", adress: "סוטין 13"  ,  city:" תל אביב"},
  { name: "Ptai",  src: "assets/projects/ptai.jpg", adress: "פטאי 13" , city:"גבעתיים" },
  { name: "Tawoer",  src: "assets/projects/tawore.jpg", adress: "Prim Tower - אריק לוי 18" , city:" נתניה" },
  { name: "Kzanelson", src: "assets/projects/kazanelson.jpg", adress: "כצנלסון 7"  , city:"גבעתיים"},
  { name: "Kakal",  src: "assets/projects/kakal.jpg", adress: 'קק״ל 7, ', city:"גבעתיים" },
];
