export const partners= [{
    id:1,
    src:'/assets/partners/haim1.jpg',
    name:'חיים עזרא',
    title:'שותף מייסד',
    desc:['בוגר לימודי ניהול פרויקטים בבנייה מטעם הטכניון. ' , 
    'סיים את לימודי הנדסאות בנין במכללת אורט והחל עבודתו בחברת אחים מרגולין ' , 'שם עסק 8 שנים בניהול פרויקטים מורכבים בתחום בנייה למגורים והתחדשות עירונית. ' , 'תוך שימת דגש מרבית על תקציב הפרויקט, איכותו וסיומו בלוז המתוכנן.']
},
{
    id:2,
      src:'/assets/partners/asaf1.jpg',
    name:'אסף דדון  ',
    title:'שותף מייסד',
    desc:['סיים לימודי הנדסאות בניין במכללת שנקר והוסמך על ידי איגוד המהנדסים לכתיבת חוות דעת מקצועיות בתחום הבנייה למגורים.' , 'עם סיום לימודיו החל עבודתו כמהנדס ביצוע בחברת מנרב שם הועסק 4 שנים כמהנדס ביצוע בפרויקטים בתחום המגורים. ' , 'לאחר מכן החל עבודתו בחברה יזמית שם ניהל פרויקטים בתחום ההתחדשות עירונית באזורים מורכבים במרכז ת"א משלב התכנון ועד מסירה לדייר.  '   ]
}]