import React from "react";
import "./index.scss";

export interface ICard {
  id: string;
  desc: string;
  title: string;
}
export const CardShowOnHover = ({ card }: { card: ICard }) => {
  return (
    <li className="card" tabIndex={0}>
      <div className="card-wrapper">
        <h4>{card.title}</h4>
        <div className="card-content">{card.desc}</div>
      </div>
    </li>
  );
};
