import { useState, useEffect } from "react";
import Project from "./Project";
import "./index.scss";
import { Button } from "@mui/material";
import useIsMobile from "../../Hooks/useIsMobile";

export interface IProject {
  src: string;
  name: string;
  adress: string;
  city: string;
}
const PROJECT_PER_PAGE = 3;
const Projects = ({ projects }: { projects: IProject[] }) => {
  const isMobile = useIsMobile();
  const [pageNumber, setPageNumber] = useState(1);
  const [visibleProjects, setVisibleProjects] = useState<IProject[] | []>([]);
  const [hasMore, setHasMore] = useState(true);

  const loadMoreProjects = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const fetchItems = () => {
    const slicedItem = projects.slice(
      (pageNumber - 1) * PROJECT_PER_PAGE,
      pageNumber * PROJECT_PER_PAGE
    );
    setVisibleProjects((prevVisibleProjects) => [
      ...prevVisibleProjects,
      ...slicedItem,
    ]);
  };

  useEffect(() => {
    if (!isMobile) setVisibleProjects(projects);
    else {
      const slicedItem = projects.slice(
        (pageNumber - 1) * PROJECT_PER_PAGE,
        pageNumber * PROJECT_PER_PAGE
      );

      setVisibleProjects(slicedItem);
    }
  }, [isMobile, projects]);

  useEffect(() => {
    if (pageNumber >= 2 && isMobile) fetchItems();
  }, [pageNumber]);

  useEffect(() => {
    if (visibleProjects.length >= projects.length && isMobile) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  }, [visibleProjects, isMobile]);

  return (
    <div className="projects sectionContainer full">
      <h2 className="sectionTitle"> פרויקטים</h2>
      <div className="projectsList" id="projects">
        {visibleProjects?.map((project, index) => {
          return <Project key={index} project={project} />;
        })}
      </div>
      {isMobile && hasMore && (
        <Button classes={{ root: "loadMore" }} onClick={loadMoreProjects}>
           לפרויקטים נוספים
        </Button>
      )}
    </div>
  );
};

export default Projects;
