import { useEffect, useState } from "react";
import "./index.scss";
import MenuIcon from "@mui/icons-material/Menu";

const NAV_BAR = 52;

const NavBar = ({ isAcsessability }: { isAcsessability: boolean }) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > window.innerHeight - NAV_BAR / 2);
    });
  }, []);

  const handelCloseMenu = () => {
    setOpen(!open);
  };
  return (
    <div className={`NavBar ${scroll || isAcsessability ? "sticky" : null}`}>
      <div className="mobile-btn" tabIndex={0}>
        <MenuIcon onClick={handelCloseMenu} />
      </div>
      <ul className={`NavBarList ${open && "open"}`} tabIndex={1}>
        <li className="item">
          <a href="#vision" onClick={handelCloseMenu}>
            {" "}
            החזון שלנו
          </a>
        </li>
        <li className="item">
          <a href="#projects" onClick={handelCloseMenu}>
             פרויקטים
          </a>
        </li>
        <li className="item">
          <a href="#about" onClick={handelCloseMenu}>
            מי אנחנו
          </a>
        </li>
        <li className="item">
          <a href="#contactus" onClick={handelCloseMenu}>
            צור קשר
          </a>
        </li>
      </ul>

      <img
        className="logo"
        src={"/assets/logo.svg"}
        alt="חיים-אסף - ניהול פרויקטים"
        tabIndex={0}
      />
    </div>
  );
};

export default NavBar;
