import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
// A custom theme for this app

 
const theme = createTheme({
   components: {
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
        
      },
       styleOverrides: {
        root: {
          fontSize: '1.25rem!important',
          color:'#fff!important',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#97c2d3' ,
    },
    secondary: {
      main: '#1e272e',
    },
    error: {
      main: red.A400,
    },
  },
});
console.log('theme' , theme)
export default theme;