import React from "react";
import "./index.scss";

const CoverImage = () => {
  return (
    <div className="CoverImage">
      <div className="overlay"></div>
    </div>
  );
};

export default CoverImage;
