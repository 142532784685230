import { useEffect, useState } from "react"
import useWindowSize from "./useWindowSize";

const useIsMobile = ()=>{
    const windowSize= useWindowSize();
    const [isMobile , setIsMobile]= useState(false);

    useEffect(()=>{
        if(windowSize.width > 600 ){
            setIsMobile(false)
        }else{
             setIsMobile(true)
        }

    },[windowSize])



    return isMobile
}

export default useIsMobile