interface IPartner {
  id: number;
  src: string;
  name: string;
  title: string;
  desc: string[];
}
const Partner = ({ partner }: { partner: IPartner }) => {
  return (
    <div className="Partner" tabIndex={0}>
      <div
        className="image"
        style={{ backgroundImage: `url(${partner.src})` }}
      ></div>
      <div className="desc">
        <h3>{partner.name}</h3>
        {partner?.desc.map((pcontent) => (
          <p>{pcontent}</p>
        ))}
      </div>
    </div>
  );
};

export default Partner;
