

export const visionList = [
{
    id:"1",
    title:"הנדסת ערך",
    desc:"מאחר ומתכנני הפרויקט עובדים תחת עומס רב ולוחות זמנים צפופים אנו מקפידים לרדת לעומקו של תכנון ולדרוש בחינת חלופות שמוזילות את עלויות הבנייה ועשויות אף לצמצם את משך הביצוע"
},
{
    id:"2",
    title:"סוף מעשה במחשבת תחילה",
    desc:"אחד מעקרונות היסוד המנחים שלנו הוא השקעת מחשבה רבה ופגישות תאום תכנון וביצוע כשהפרויקט עודו בוסר, ובכך לצמצם באופן משמעותי מאוד סטיות בתקציב ובלוז."
},{
    id:"3",
    title:"חדשנות",
    desc:"חשיבה חדשנית ומודרנית בגיוס כל הכלים הטכנולוגיים, ברוח צעירה ובשאיפת שיפור מתמדת.יכלים, אמצעים טכנולוגיים ושיטות חדשניות בפעילותנו השוטפת."
},{
    id:"4",
    title:"זמינות",
    desc:"מרגע חתימת ההסכם חברתנו נכנסת כשותפה לפרויקט על כל המשתמע מכך ולכן תבטיח טיפול ראוי וזמינות מרבית לאורך כל חיי הפרויקט."
},{
    id:"5",
    title:"אלוהים נמצא בפרטים הקטנים",
    desc:"לאור המורכבות של פרויקט בשלב ביצוע אנו שמים לעצמנו כמטרה מחייבת לשימת לב וירידה לפרטים הקטנים שזהו תנאי מפתח להצלחה."
},
{
    id:"6",
    title:"תודעת שירות ללקוח קצה",
    desc:"הלקוח של המזמין זה הלקוח שלנו ולכן בערכנו לשמור על שביעות רצונו של הדייר, עד כה מסרנו כ 400 יחידות דיור בדגש על שביעות רצון הדיירים."
}
]