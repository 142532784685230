import { IProject } from ".";

const Project = ({ project }: { project: IProject }) => {
  const { src, adress, city } = project;
  return (
    <div className="project" tabIndex={0}>
      <div className="src-bg" style={{ backgroundImage: `url(${src})` }}></div>
      <div className="overlay"></div>
      <a href="#" className="content">
        <div className="adress">
          {adress}, {city}
        </div>
      </a>
    </div>
  );
};

export default Project;
