import AccessibleIcon from "@mui/icons-material/Accessible";

const Accessiblty = ({ onChange }: { onChange: () => void }) => {
  return (
    <button className="Accessiblty" onClick={onChange}>
      <AccessibleIcon />
    </button>
  );
};

export default Accessiblty;
