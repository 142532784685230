import { tools } from "../../data/tools";
import DesktopCards from "./DesktopCards";
import MobileCards from "./MobileCards";
import useIsMobile from "../../Hooks/useIsMobile";
import "./index.scss";

const Tools = () => {
  const isMobile = useIsMobile();

  return (
    <div className="OuerValues sectionContainer" id="tools">
      <h2 className="sectionTitle">כלים לניהול</h2>
      {isMobile ? (
        <MobileCards tools={tools ?? []} />
      ) : (
        <DesktopCards tools={tools ?? []} />
      )}
    </div>
  );
};

export default Tools;
