import About from "../../components/About";
import CoverImage from "../../components/CoverImage";
import Tools from "../../components/Tools";
import OurVision from "../../components/OuerVision";
import Projects from "../../components/Projects";
import ContactUs from "../../components/ContactUs/indes";
import { projects } from "../../data/projects";

const Home = ({ isAcsessability }: { isAcsessability: boolean }) => {
  return (
    <div>
      {!isAcsessability ? <CoverImage /> : null}
      <OurVision />
      <Projects projects={projects} />
      <About />
      <Tools />
      <ContactUs />
    </div>
  );
};
export default Home;
