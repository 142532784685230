import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import EngineeringIcon from '@mui/icons-material/Engineering';
import BackupTableIcon from '@mui/icons-material/BackupTable';

export const tools = [
  {
    id: 1,
    title: "צ'ק ליסט",
    icon: CheckRoundedIcon,
    desc: 'כיוצאי חברה שמייסדה יוצאי טייסת הפקנו במהלך הדרך רשימות תיוג ברמה גבוהה מאוד תוך ירידה לפרטי פרטים ובניית מעקב משימות לכל אחד משלבי הפרויקט החל מהגשת התב"ע וועד לאכלוס הפרויקט ומסירת כל המערכות לחברת הניהול.  ',
  },
  {
    id: 2,
    title: "בקרת איכות",
    icon: ContentPasteSearchIcon,
    desc: " אנו מאמינים בגיוס הטכנולוגיה מעולם ההייטק לעולם ההנדסה ולכן מקפידים ודורשים התחברות לאפליקציית בקרת איכות אשר בעזרתה נמדדת איכותן של כל המלאכות בפרויקט. ",
  },
  {
    id: 3,
    title: " ms project",
    icon: EngineeringIcon,
    desc: " לאור הבנת החשיבות הרבה של לוז הפרויקט ובייחוד בפרויקטים של התחדשות עירונית הכוללים דיירים מפונים עם זמני חזרה קצובים מראש אנו מקפידים לשמור בקנאות על לוז הפרויקט תוך הובלת הגורם המבצע להתכנסות לאבני דרך המוכתבות מחדש בכל פגישה שבועית ומתאומות למצב הפרויקט העדכני ביחס ללוז המחייב. ",
  },
  {
    id: 4,
    title: "טבלאות שליטה לחיבורי מערכות",
    icon: BackupTableIcon,
    desc: "כידוע ההתנהלות מול התאגידים הציבוריים בעניין חיבור הפרויקט לתשתיות הינו הליך מורכב וארוך אשר חייב לקבל התייחסות החל משלב התכנון ולכן אנו מנהלים בקרה צמודה לכל אורך הפרויקט הן על התאגידים והן על המתכננים ובכך מבטיחים הימנעות ממצב של עיכובים הנגרמים בעקבות אי קבלת חיבור בזמן.   ",
  },
];
