export interface IValueCard {
  id: number;
  title: string;
  icon: any;
  desc: string;
}
const Card = ({ card }: { card: IValueCard }) => {
  const Icon = card.icon;
  return (
    <div className="ValueCard" tabIndex={0}>
      <div className="topSection">
        <div className="title">{card.title}</div>
        <div className="icon">{<Icon />} </div>
      </div>
      <div className="desc">{card.desc}</div>
    </div>
  );
};

export default Card;
