import { Pagination, Scrollbar, A11y } from "swiper/modules";
import { CardShowOnHover, ICard } from "../CardShowOnHover/index";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/scss/pagination";

const MobileValues = ({ items }: { items: ICard[] | [] }) => {
  return (
    <Swiper
      modules={[Pagination, Scrollbar, A11y]}
      spaceBetween={0}
      slidesPerView={1}
      navigation
      pagination={{
        clickable: true,
        bulletClass: "bullet-span",
        bulletActiveClass: "bullet-span-active",
      }}
      scrollbar={{ draggable: true }}
    >
      {items.map((item) => (
        <SwiperSlide>
          <CardShowOnHover card={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MobileValues;
