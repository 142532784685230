import { partners } from "../../data/partners";
import Partner from "./Partner";
import "./index.scss";

const About = () => {
  return (
    <div className="About sectionContainer" id="about">
      <h2 className="sectionTitle">מי אנחנו</h2>
      <div className="Partners">
        {partners.map((partner, index) => (
          <Partner key={index} partner={partner} />
        ))}
      </div>
    </div>
  );
};

export default About;
