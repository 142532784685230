import "./index.scss";
import useIsMobile from "../../Hooks/useIsMobile";
import DesktopValues from "./DesktopValues";
import MobileValues from "./MobileValues";
import { visionList } from "../../data/vision";
const OurVision = () => {
  const isMobile = useIsMobile();
  return (
    <div className="sectionContainer ourVision" id="vision">
      <h2 className="sectionTitle"> החזון שלנו</h2>
      <div className="main-content">
        <div className="desc" tabIndex={0}>
          חברת חיים אסף מתמחה בפיקוח וניהול פרויקטים הנדסיים, תוך שאיפה למצוינות
          ואיכות בלתי מתפשרת. <br />
          אנו מלווים את הפרויקט באדיקות ובדייקנות החל משלב הרישוי ועד לשלב המרגש
          של מסירת המפתח לדייר תוך ירידה לפרטים, הנדסת ערך ושיפור מתמיד. אנו
          רואים עצמנו כאחראים העיקריים להבאת הפרויקט לסיום מוצלח בתקציב, באיכות
          ובמועד הנדרש.
        </div>
        <h3 className="sectionTitle sub"> הערכים שלנו</h3>
        {isMobile ? (
          <MobileValues items={visionList ?? []} />
        ) : (
          <DesktopValues items={visionList ?? []} />
        )}
      </div>
    </div>
  );
};
export default OurVision;
