import Card, { IValueCard } from "./ValueCard";

const DesktopCards = ({ tools }: { tools: IValueCard[] }) => {
  return (
    <div className="cards">
      {tools?.map((tool) => (
        <Card key={tool.id} card={tool} />
      ))}
    </div>
  );
};

export default DesktopCards;
