import { useState } from "react";
import "./App.scss";
import Home from "./Pages/Home";
import Accessiblty from "./components/Accessiblty";
import NavBar from "./components/NavBar";

function App() {
  const [isAcsessability, setIsAcsessability] = useState(false);
  return (
    <div className="App" data-theme={isAcsessability && "acsess"}>
      <NavBar isAcsessability={isAcsessability} />
      <Home isAcsessability={isAcsessability} />
      <Accessiblty onChange={() => setIsAcsessability(!isAcsessability)} />
    </div>
  );
}

export default App;
