import { Link } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CallIcon from "@mui/icons-material/Call";
import "./index.scss";

const EMAIL = "haimasaf5@gmail.com";
const PHONE_NUMBER = "972523815844";

const ContactUs = () => {
  return (
    <div className="ContactUs sectionContainer" id="contactus">
      <div className="grid">
        <div className="image"></div>
        <div className="contact">
          <div className="contact-wrap">
            <h2 className="sectionTitle">רוצים לשמוע עוד פרטים </h2>
            <div className="desc">מוזמנים ליצור איתנו קשר ונענה בקדם! </div>
            <div className="contact-ways">
              <Link href={`https://wa.me/${PHONE_NUMBER}`}>
                <WhatsAppIcon />
                <span>שלחו הודעה</span>
              </Link>

              <Link
                href={`mailto:${EMAIL}`}
                target="_top"
                rel="noopener noreferrer"
              >
                <MailOutlineIcon />
                <span>שלחו מייל</span>
              </Link>
              <Link href={`tel:${PHONE_NUMBER}`}>
                <CallIcon />
                <span>התקשרו</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
